@import '@coreui/coreui/scss/functions';
@import '@coreui/coreui/scss/variables';
@import '@coreui/coreui/scss/mixins';
@import './variables';
::-ms-input-placeholder {
  /* Edge 12-18 */
  font-family: $font-family-sans-pro;
  font-size: var(--fsz-14);
  color: #6d7581;
}

::placeholder {
  font-family: $font-family-sans-pro;
  font-size: var(--fsz-14);
  color: #6d7581;
}

.form-outer {
  background: linear-gradient(180deg, #f9f2ec 39.06%, rgba(255, 255, 255, 0) 100%);
  padding-top: 51px;
  margin-bottom: 51px;
  .form-i {
    height: min-content;
    .auth-form {
      max-width: 598px;
      border-radius: 10px;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 90px;
      box-shadow: 0px 11px 20px 0px rgba(0, 0, 0, 0.03);
      @include media-breakpoint-down(sm) {
        max-width: 100%;
        padding: 20px 10px;
      }
      h1 {
        font-size: var(--fsz-36);
        font-weight: 600;
        color: #000;
        margin-bottom: 30px;
        text-align: center;
      }
      p {
        font-family: $font-family-sans-pro;
        font-size: var(--fsz-14);
        max-width: 340px;
        text-align: center;
        margin-bottom: 30px;
      }
      .auth-btn-grp {
        display: flex;
        gap: 10px;
        .social-login {
          max-width: fit-content;
          padding: 0px;
        }
      }
      .auth-btn {
        background: none;
        border-radius: 7px;
        background: #fff;
        filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.04));
        backdrop-filter: blur(6px);
        border: 1px solid lightgray;
        padding: 0 30px;
        height: 50px;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        color: #6d7581;
      }
      .or_b {
        color: $gray-color;
        padding: 25px 0px;
        font-family: Source Sans Pro;
        font-size: var(--fsz-12);
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        .line {
          flex: 1;
          border-top: 1px solid $gray-color;
          content: '';
          opacity: 0.3;
        }
        .text {
          flex: 1;
          text-wrap: nowrap;
        }
      }
      .form-f {
        .form-control {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          position: relative;
          font-size: var(--fsz-16);
          position: relative;
        }

        input,
        button {
          max-width: 100%;
          width: 420px;
          height: 50px;
          margin-bottom: 15px;
          @include media-breakpoint-down(sm) {
            width: 100%;
          }
        }

        .invalid-feedback {
          margin-top: -0.75rem;
          margin-bottom: 0.5rem;
        }

        .form-control::placeholder {
          opacity: 1;
          transition: all 0.3s;
          font-size: var(--fsz-14);
          font-family: Source Sans Pro;
          position: absolute;
        }

        // .form-control.has-text::placeholder {
        //   transform: translateY(-200%);
        //   opacity: 0;
        // }

        .form-control:focus::placeholder {
          transform: translateY(-85%);
          opacity: 1;
          font-size: 11px;
          font-family: Source Sans Pro;
        }

        .f_pass_link {
          font-family: Source Sans Pro;
          font-size: var(--fsz-14);
          font-weight: 500;
          color: #0e3c55;
          text-align: center;
          font-family: Quicksand;
          text-decoration: underline;
          margin: 16px 0px 15px;
          cursor: pointer;
        }
        .sign_up_t {
          color: $gray-color;
          font-family: Source Sans Pro;
          text-align: center;
          font-size: var(--fsz-16);
          margin: 15px 0px 0px;
          span {
            text-decoration: underline;
            color: $orange;
            cursor: pointer;
          }
        }
      }
      .mb-47 {
        margin-bottom: 47px;
      }
    }
  }
}
