// Here you can add other styles
:root {
  --fsz-11: 11px;
  --fsz-12: 12px;
  --fsz-13: 13px;
  --fsz-14: 14px;
  --fsz-15: 15px;
  --fsz-16: 16px;
  --fsz-18: 18px;
  --fsz-20: 20px;
  --fsz-36: 36px;
  --fsz-50: 50px;

  --lh-13: 13px;
  --lh-14: 14px;
  --lh-16: 16px;
  --lh-18: 18px;
  --lh-19: 19px;
  --lh-20: 20px;
  --lh-23: 23px;
  --lh-24: 24px;
  --lh-26: 26px;
  --lh-28: 28px;
  --lh-30: 30px;
  --lh-32: 32px;
  --lh-44: 44px;
  --lh-59: 59px;

  --screen-break-1: 1600;
  --screen-break-2: 1900;
}

@media (min-width: $screen-breakpoint-1) {
  :root {
    --fsz-11: 13px;
    --fsz-12: 14px;
    --fsz-13: 15px;
    --fsz-14: 16px;
    --fsz-15: 17px;
    --fsz-16: 18px;
    --fsz-18: 20px;
    --fsz-20: 22px;
    --fsz-36: 38px;
    --fsz-50: 52px;

    --lh-13: 15px;
    --lh-14: 16px;
    --lh-16: 18px;
    --lh-18: 20px;
    --lh-19: 21px;
    --lh-20: 22px;
    --lh-23: 25px;
    --lh-24: 26px;
    --lh-26: 28px;
    --lh-28: 30px;
    --lh-30: 32px;
    --lh-32: 34px;
    --lh-44: 46px;
    --lh-59: 61px;
  }
}

@media (min-width: 2000px) {
  :root {
    --fsz-11: 15px;
    --fsz-12: 16px;
    --fsz-13: 17px;
    --fsz-14: 18px;
    --fsz-15: 19px;
    --fsz-16: 20px;
    --fsz-18: 22px;
    --fsz-20: 24px;
    --fsz-36: 40px;
    --fsz-50: 54px;

    --lh-13: 17px;
    --lh-14: 18px;
    --lh-16: 20px;
    --lh-18: 22px;
    --lh-19: 23px;
    --lh-20: 24px;
    --lh-23: 27px;
    --lh-24: 28px;
    --lh-26: 30px;
    --lh-28: 32px;
    --lh-30: 34px;
    --lh-32: 36px;
    --lh-44: 48px;
    --lh-59: 63px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.body {
  background-color: #f9f9f9 !important;
}
.initial-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #faf1ee;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100vw;
  }
}

.loading-effect {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

.wrapper {
  padding-left: 0 !important;
}
.header-brand {
  margin-right: 0px !important;
  padding: 0;
  .h-logo {
    width: 280px;
    @include media-breakpoint-down(sm) {
      height: auto;
      max-width: 200px;
    }
    @media (max-width: 400px) {
      height: auto;
      max-width: 171px;
    }
  }
}

.toast {
  color: #ffffff;
  z-index: 10000;
  width: 400px;
  max-width: 100%;
  .t-container {
    padding: 20px 14px;
    position: relative;
    img {
      margin-right: 15px;
    }
    .toast-body {
      padding: 0px;
    }
    .toast-head {
      font-size: var(--font-size-16);
      line-height: var(--lh-16);
      color: #ffffff;
      margin-bottom: 10px;
    }
    .toast-message {
      font-weight: 400;
      font-size: var(--font-size-14);
      line-height: var(--lh-16);
      color: #ffffff;
      width: 280px;
    }
    button {
      padding: 0;
      background: transparent;
      border: none;
      position: absolute;
      right: 15px;
      top: 15px;
      img {
        margin: 0;
      }
    }
  }
}

.loader-container {
  position: fixed;
  background: #c0cfd375;
  width: 100%;
  height: 100vh;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-box {
  .counter {
    font-size: var(--fsz-12);
    width: 22px;
    height: 22px;
    border-radius: 22px;
    background: $orange;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    right: -11px;
    top: -14px;
  }
}

.user-dropdown {
  .dropdown-toggle {
    display: flex;
    gap: 5px;
    align-items: center;
    &::after {
      content: url('../../../public/assets/icons/down.svg');
      border: none;
    }
  }
  .dropdown-menu {
    margin-top: 10px !important;
  }
  .text {
    text-transform: none;
    letter-spacing: normal;
    font-size: var(--fsz-14);
    line-height: var(--lh-24);
  }
  @include media-breakpoint-down(md) {
    .text {
      display: none;
    }
  }
}

.user-dropdown-logout {
  .dropdown-toggle {
    &::after {
      content: none;
    }
  }
}

.icon {
  width: 100% !important;
  height: 23px !important;
}

.box-container {
  margin: 0px 50px;
  @include media-breakpoint-down(md) {
    margin: 0px 15px;
  }
}

h2 {
  font-size: var(--fsz-36);
  color: $primary;
  line-height: var(--lh-28);
  margin-bottom: 0;
  span {
    color: $orange;
    &.blue {
      color: $blue;
    }
  }
  @include media-breakpoint-down(md) {
    font-size: 24px;
    text-align: left;
  }
}

.header {
  .right-menu {
    gap: 30px;
    margin: 0;
    list-style: none;
  }
  
  @media only screen and (max-width: 1200px) and (min-width: 1026px) {
    &.header-sticky {
      padding: 0 25px;
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 19px 16px;
    .mobile-header-toggle,
    .right-menu {
      padding: 0;
      gap: 15px;
      flex: 1;
      .search-icon {
        width: 32px;
        height: 32px;
      }
    }
    .mobile-header-toggle {
      justify-content: flex-start !important;
    }
    .right-menu {
      justify-content: flex-end !important;
    }
    .container-fluid {
      min-height: auto !important;
      padding: 0px;
    }
    .h-i {
      width: 25px;
      height: 25px;
    }
    .hamburger-menu-img {
      width: 26px;
      height: 20px;
    }
  }
  @include media-breakpoint-between(md, lg) {
    .header-brand {
      overflow: hidden;
      margin-right: 35px !important;
    }
    .container-fluid {
      min-height: auto !important;
      padding: 0px;
    }
    .mobile-header-toggle,
    .right-menu {
      gap: 20px;
      padding: 0;
    }
    .search-box .input-group {
      border: 1px solid #e2e2e2;
      border-radius: 6px;
      width: 230px;
    }
  }

  @media (min-width: 768px) and (max-width: 835px) {
    .search-box .input-group {
      width: 135px;
      .input-group-text {
        padding: 5px;
      }
      .form-control {
        padding-left: 5px;
      }
    }
  }

  @include media-breakpoint-between(lg, xl) {
    //padding: 25px 20px;
    .header-brand {
      width: auto;
      margin-right: 10px !important;
    }
    .container-fluid {
      min-height: auto !important;
      padding: 0px;
    }
    .mobile-header-toggle,
    .right-menu {
      gap: 30px;
    }
    .search-box .input-group {
      width: 210px;
    }
  }
}
.sidebar-brand {
  padding: 25px;
  img {
    max-width: 100%;
    height: auto;
  }
}
@include media-breakpoint-up(lg) {
  .sidebar {
    display: none;
  }
}

.nav-link {
  color: $primary;
  font-size: var(--fsz-12);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1.32px;
  text-transform: uppercase;
}

.form-control {
  border: unset;
  &:focus {
    box-shadow: none;
    border: unset;
  }
}
button {
  box-shadow: none !important;
}

.search-box {
  .input-group {
    height: 45px;
    width: 411px;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    @include media-breakpoint-down(xxl) {
      width: 300px;
    }
  }
  .search-text {
    color: #36373f;
    font-family: $font-family-sans-pro;
    font-size: var(--fsz-20);
    font-weight: 400;
    letter-spacing: 0.4px;
    margin: 0 0 0 8px;
  }
  .input-group-text {
    background-color: $white;
    border: unset;
    padding: 0px 13px;
    img {
      width: 22px;
    }
  }
  .form-control {
    border-left: unset !important;
    background-color: $white;
    color: $primary;
    font-size: var(--fsz-14);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.28px;
  }
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bababa;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bababa;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bababa;
}

.footer {
  padding: 0px;
  color: #6d7581;
  flex-direction: column;
  font-size: var(--fsz-12);
  line-height: var(--lh-19);
  font-weight: 400;
  .footer-two {
    padding-top: 125px !important;
    @include media-breakpoint-down(sm) {
      padding-top: 95px !important;
    }
  }
  .footer-menu {
    flex: 1;
    padding: 65px 165px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      padding: 40px 20px;
      flex-direction: column;
    }
    @include media-breakpoint-between(sm, xl) {
      flex-wrap: wrap;
      gap: 20px;
      padding: 40px 20px;
    }
    h3,
    h6 {
      color: #474747;
      font-weight: 600;
    }
    .text-secondary {
      text-decoration: underline;
    }
    .fmenu {
      //width: 150px;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      .heading {
        margin-bottom: 35px;
        @include media-breakpoint-down(md) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0px;
          padding: 20px 0px;
          border-top: 1px solid #245570;
          &.b-top {
            padding-top: 30px;
            padding-bottom: 0px;
          }
          &.margin-none-on-device {
            margin-bottom: 0px;
          }
        }
        h2 {
          color: $primary;
          font-size: var(--fsz-16);
          font-style: normal;
          font-weight: 600;
          line-height: var(--lh-24);
          margin-bottom: 0px;
        }
        i {
          font-size: var(--fsz-20);
          font-style: normal;
          color: $primary;
        }
      }
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0px;
        gap: 10px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 32px;
          &.hide-on-device {
            display: none;
          }
        }
      }
    }
    .fmenu-1 {
      width: 230px;
      @media only screen and (min-width: $screen-breakpoint-1) {
        width: 430px;
      }
      .f-logo {
        width: 100%;
      }
      p {
        margin-top: 33px;
        margin-bottom: 24px;
      }
      .mail-to {
        margin-left: 10px;
      }
      ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        gap: 13px;
        margin-top: 24px;
        padding: 0;
        @include media-breakpoint-down(sm) {
          justify-content: center;
        }
      }
      @include media-breakpoint-down(sm) {
        align-self: center;
        text-align: center;
      }
    }
    a {
      color: #6d7581;
      text-decoration: unset;
      font-family: $font-family-sans-pro !important;
    }
    .fmenu-5 {
      // width: 250px;
      @include media-breakpoint-down(sm) {
        width: 100%;
        .heading {
          border-bottom: unset;
          padding-bottom: 25px !important;
        }
      }
      input {
        height: 45px;
        color: #9e9e9e;
        background: #ededed;
        font-size: var(--fsz-12);
        font-style: normal;
        font-weight: 400;
        line-height: var(--lh-20);
      }
      button {
        margin-top: 12px;
        width: 100%;
        height: 47px;
        color: #fff;
        border: none;
        font-size: var(--fsz-14);
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.14px;
      }
    }
  }
  .copyright {
    border-top: 1px solid #ebebeb;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    padding: 20px 0px;
    line-height: var(--lh-30);
  }
  img {
    width: 26px;
    height: 26px;
  }
  .support-envolope-icon-style {
    width: 16px;
    height: 12px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.main-menu {
  //gap: 70px;
  margin: 0;
  padding: 0;
  justify-content: space-between;

  .dropdown {
    position: unset;
    display: inline-block;
    .dropbtn {
      color: $primary;
      font-size: var(--fsz-16);
      font-style: normal;
      font-weight: 700;
      letter-spacing: 1.6px;
      text-transform: uppercase;
      cursor: pointer;

      height: 70px;
      width: 140px;
      margin-top: 20px;
      padding: 16px 0px 25px 0px;
      text-align: center;
      position: relative;
      border-radius: 15px 15px 0 0;
    }
    &:hover,
    &.active {
      .dropbtn {
        &::after {
          content: '';
          width: 20px;
          height: 20px;
          bottom: 0px;
          right: -20px;
          position: absolute;
          border-radius: 0px 20px 0px 0px;
        }
        &::before {
          content: '';
          width: 20px;
          height: 20px;
          bottom: 0px;
          left: -20px;
          position: absolute;
          border-radius: 20px 0px 0px 0px;
        }

        span {
          &::after {
            content: '';
            position: absolute;
            width: 40px;
            height: 40px;
            background: #f9f9f9;
            border-radius: 40px;
            right: -40px;
            z-index: 2;
            bottom: 0px;
          }
          &::before {
            content: '';
            position: absolute;
            width: 40px;
            height: 40px;
            background: #f9f9f9;
            border-radius: 40px;
            left: -40px;
            z-index: 2;
            bottom: 0px;
          }
        }
        background: #f1e5df;
        &::after,
        &::before {
          background: #f1e5df;
        }
        color: $secondary;
        &.menu-1 {
          color: $blue;
          background-image: linear-gradient(to right, #e2f3f6, #e2f3f6);
          &::after,
          &::before {
            background-image: linear-gradient(to right, #e2f3f6, #e2f3f6);
          }
        }
      }
    }

    .dropdown-content {
      display: none;
      position: absolute;
      opacity: 0;
      border-radius: 8px;
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
      border-radius: unset;
      z-index: 1;
      justify-content: center;
      align-items: center;
      background: #f1e5df;
      width: 100%;
      //transition: all 1s;
      //position: fixed;
      left: 0;
      .submenu-level2 {
        position: absolute;
        width: 94vw;
        background: #fff;
        margin: 0px 30px;
        top: 45px;
      }
      .submenu {
        display: none;
        position: absolute;
        user-select: none;
        bottom: -15px;
        width: 90%;
        background: #fafafa;
        left: 5%;
        top: 45px;
        //padding: 30px 23px 0px;
        height: min-content;
        box-shadow: 0px 10px 10px 30px rgba(0, 0, 0, 0.03);
        .desktop-navigations {
          & > ul {
            display: flex;
            padding: 0;
            list-style: none;
            flex-wrap: wrap;
            background: #ffffff;
            & > li {
              min-width: 210px;
              padding-left: 23px;
              padding-top: 30px;
              padding-bottom: 20px;
              &:nth-child(2n) {
                background: #fafafa;
              }
              .subnav-heading {
                margin: 5px 0px 23px;
                color: #6d7581;
                font-size: var(--fsz-12);
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.12px;
              }
              ul {
                padding: 0;
                display: flex;
                list-style: none;
                flex-direction: column;
                gap: 20px;
                margin-bottom: 35px;
                li {
                  color: $primary;
                  font-size: var(--fsz-14);
                  font-style: normal;
                  font-weight: 400;
                  line-height: var(--lh-14); /* 100% */
                  letter-spacing: 0.14px;
                }
              }
            }
          }
        }
      }
      a {
        padding: 15px 25px;
        color: $primary;
        font-size: var(--fsz-14);
        font-style: normal;
        font-weight: 400;
        line-height: var(--lh-14); /* 100% */
        letter-spacing: 0.14px;
        display: block;
        min-width: fit-content;
        text-decoration: none;
        &.active {
          color: $secondary !important;
          .submenu {
            color: $primary;
            font-weight: normal;
          }
        }
        &:hover {
          //background-color: #f3f3f3;
          color: $secondary;
          font-weight: bold;
          display: flex;
          .submenu {
            display: block;
            color: $primary;
            font-weight: normal;
            .level-4 li {
              &:hover {
                color: $secondary;
              }
            }
          }
        }
      }
      &.menu-1 {
        background-image: linear-gradient(to right, #e7f1f3, #e2f3f6, #e7f1f3);
        a:hover,
        a.active {
          color: $blue !important;
        }
      }
    }
    &:hover .dropdown-content {
      z-index: 2;
      opacity: 1;
      display: flex;
    }
    &.active .dropdown-content {
      z-index: 1;
      opacity: 1;
      display: flex;
    }
    &:hover .dropbtn {
      color: $secondary;
      &.menu-1 {
        color: $blue;
      }
      img {
        transform: rotate(180deg);
      }
    }
  }

  #main-menu-0.one-checked-true.un-hovered {
    span::after {
      background: transparent;
    }
  }
  #main-menu-1.one-checked-true.hovered {
    z-index: 2;
    span::before {
      background: #f1e5df;
    }
  }
  #main-menu-1.one-checked-true.hovered.active {
    span::before {
      background: #f9f9f9;
    }
  }

  #main-menu-0.one-checked-true.hovered {
    z-index: 2;
    span::after {
      background: #e2f3f6;
    }
  }

  #main-menu-1.one-checked-true.un-hovered {
    span::before {
      background: #f1e5df;
    }
  }

  #main-menu-0.one-checked-true.hovered.active {
    span::after {
      background: #f9f9f9;
    }
  }
}

.cbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: $primary;
  overflow: hidden;
  font-weight: 600;
  font-size: var(--fsz-20);
  font-style: normal;
  letter-spacing: 0.2px;
  z-index: 2;
  border-radius: 10px;
  border: none;
  padding: 0px 30px;
  justify-content: flex-start;
  position: relative;
  width: 244px;
  height: 70px;
  span {
    z-index: 2;
  }
  @include media-breakpoint-down(md) {
    width: 165px;
    height: 50px;
  }
  @include media-breakpoint-between(md, xl) {
    width: 170px;
    height: 50px;
    padding: 10px 20px;
    font-size: var(--fsz-16);
  }
  @include media-breakpoint-up(xxl) {
    max-width: 280px;
    width: 100%;
  }
  &::after {
    content: url(../../../public/assets/icons/arrow-white.svg);
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 40px;
    display: flex;
    position: absolute;
    align-items: center;
    right: 30px;
    transition: 0.5s;
    @include media-breakpoint-down(md) {
      content: url(../../../public/assets/icons/arrow-white-small.svg);
      width: 28px;
      height: 28px;
      right: 20px;
    }
    @include media-breakpoint-between(md, xl) {
      content: url(../../../public/assets/icons/arrow-white-small.svg);
      width: 20px;
      height: 20px;
      right: 20px;
    }
  }
  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    transition: 0.4s;
    //width: 40px;
    border-radius: 40px;
    //height: 40px;
    right: 30px;
    // animation
    animation: btnAnimateReverse 0.4s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    right: 0;
    height: inherit;
    z-index: 1;
  }
  &:hover {
    color: #ffffff;
    &::before {
      animation: btnAnimate 0.4s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      right: 0;
      height: inherit;
      z-index: 1;
    }
    &::after {
      z-index: 2;
      transition: 0.4s;
      right: 20px;
    }
  }
  &.orng {
    &:hover {
      &::before {
        background-color: $secondary;
      }
    }
    &::after {
      background-color: $secondary;
    }
  }
  &.blue {
    &:hover {
      &::before {
        background-color: $blue;
      }
    }
    &::after {
      background-color: $blue;
    }
  }
  &.no-hover {
    &:hover {
      &::after,
      &::before {
        background-color: unset !important;
        background: unset !important;
      }
      color: $primary !important;
      &::after {
        right: 20px !important;
      }
    }
  }
  @include media-breakpoint-down(md) {
    border-radius: 8px;
    font-size: var(--fsz-14);
    padding: 0px 20px;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    color: $orange;
    border: 1px solid $orange;
    font-size: var(--fsz-16);
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.16px;
    padding: 11px 20px;
    width: 100%;
    &.blue {
      color: $blue;
      border: 1px solid $blue;
    }
    &::before,
    &::after {
      content: none;
      width: 0;
      height: 0;
    }
    &:hover {
      color: unset;
    }
  }
}

@keyframes btnAnimate {
  0% {
    width: 0px;
    height: 0px;
    border-radius: 50%;
    right: 33px;
  }
  5% {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    right: 30px;
  }
  15% {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    right: 20px;
  }
  25% {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }
  50% {
    width: 150px;
    height: 150px;
    border-radius: 150px;
  }
  75% {
    width: 200px;
    height: 200px;
    border-radius: 200px;
  }
  100% {
    width: 500px;
    height: 500px;
    border-radius: 0;
  }
}

// Animatio revese
@keyframes btnAnimateReverse {
  0% {
    width: 500px;
    height: 500px;
    border-radius: 0;
  }
  5% {
    width: 200px;
    height: 200px;
    border-radius: 200px;
  }
  15% {
    width: 150px;
    height: 150px;
    border-radius: 150px;
  }
  25% {
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }
  50% {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    right: 20px;
  }
  75% {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    right: 30px;
  }
  100% {
    width: 0px;
    height: 0px;
    border-radius: 50%;
    right: 33px;
  }
}

.bg-pk {
  background: #faf3ed;
  position: relative;
  &.bg-img {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 434px;
      max-width: 100%;
      height: 430px;
      background-image: url('../../../public/assets/images/background-1.png');
    }
  }
  background-image: radial-gradient(
      farthest-side at bottom left,
      #f0ded6,
      #f0ded6,
      rgba(243, 177, 142, 0.5),
      #f0ded6,
      transparent 90%
    ),
    radial-gradient(farthest-side at top right, #e9edea, #e9ede9, #e9ede9, transparent 90%);
}

.container-full {
  margin: auto 0px auto 0px;
}

.container-70 {
  margin: auto 70px auto 70px;
  @include media-breakpoint-down(lg) {
    margin: 0px;
  }
}

.container-ml-70 {
  margin-left: 70px;
  @include media-breakpoint-down(lg) {
    margin: 0px;
  }
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-ml-20 {
  @include media-breakpoint-down(lg) {
    margin: 0px 15px;
  }
}
.mb-lr-20 {
  @include media-breakpoint-down(lg) {
    margin: 0px 20px;
  }
}

.mt-80 {
  margin-top: 80px;
  @include media-breakpoint-down(lg) {
    &.mt-md-50 {
      margin-top: 50px;
    }
    &.mt-md-30 {
      margin-top: 30px;
    }
  }
}

.slick-slider {
  &.blur-last-item {
    .slick-list {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 100px;
        height: 376px;
        bottom: 0;
        right: 0;
        background: linear-gradient(
          92deg,
          rgba(249, 249, 249, 0) 1.68%,
          rgba(249, 249, 249, 0.99) 50.83%
        );
        @media only screen and (min-width: $screen-breakpoint-1) {
          height: 410px !important;
        }
        @include media-breakpoint-down(md) {
          content: unset;
        }
      }
    }
    &.latest-videos-slider {
      .slick-list {
        &::after {
          height: 430px;
        }
      }
    }
  }

  &.blur-first-item {
    .slick-list {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 100px;
        height: 376px;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          92deg,
          rgba(249, 249, 249, 0.99) 1.68%,
          rgba(249, 249, 249, 0) 50.83%
        );
      }
    }
    &.latest-videos-slider {
      .slick-list {
        &::after {
          height: 430px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .slick-arrow {
      display: none !important;
    }
  }
}

.ff-sans-pro {
  font-family: $font-family-sans-pro;
}
@include media-breakpoint-down(lg) {
  .header-sticky {
    min-height: 0;
    padding-bottom: 0px;
    .container-fluid {
      align-items: flex-start;
      position: relative;
      height: 115px;
      &.no-search {
        height: 55px;
      }
      .search-box {
        position: absolute;
        bottom: 22px;
        left: 0;
        width: 100%;
        .input-group {
          border: none;
          border-radius: 6px;
          width: 100%;
          display: flex;
          flex-direction: row-reverse;
          border: 1px solid #e2e2e2;
          border-radius: 6px;
          input {
            border-left: none;
            background: #fff;
            margin-left: 0px !important;
            border-radius: 6px !important;
          }
          .input-group-text {
            background: #fff;
            border-radius: 6px !important;
          }
        }
      }
    }
  }
  .latest-videos .head-style {
    margin-bottom: 0;
  }
  .review-slider-box .head-style,
  .blog-slider-box .head-style {
    margin-bottom: 0px;
  }
}

a:hover {
  color: $orange !important;
}
.custom-link {
  text-decoration: none !important;
}

.subscribe-form {
  .error-msg {
    position: absolute;
    color: red;
    text-align: right;
    bottom: -36px;
    left: 3px;
    background: #fff;
    padding: 2px 5px;
    border-radius: 4px !important;
    box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
    &::after {
      border-bottom: 10px solid #fff;
      border-left: 12px solid #0000;
      border-right: 12px solid #0000;
      content: "";
      position: absolute;
      top: -10px;
      left: 4px;
    }
  }
}
.alert .icon {
  width: auto !important;
}

.btn {
  font-size: 14px;
  padding: 0px 30px;
  height: 50px;
  &.orange {
    background-color: $orange;
    border: 1px solid $orange;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 0px; /* 0% */
    letter-spacing: 0.14px;
  }
  &.transparent {
    border: 1px solid #DBDBDB;
    height: 40px;
    padding: 0px 20px;
    color: #36373F;
    font-size: var(--fsz-12);
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.12px;
    font-family: $font-family-quicksand;
    &:hover {
      background-color: #DBDBDB;
    }
  }
  &.blue {
    border: 1px solid $primary;
    height: 40px;
    padding: 0px 20px;
    background: $primary;
    color: #ffffff;
    font-size: var(--fsz-12);
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.12px;
    font-family: $font-family-quicksand;
    &:hover {
      background-color: #164b68;
    }
  }
}

.form-floating {
  .form-control {
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    font-size: 16px;

    color: $primary;
    font-family: $font-family-sans-pro;
    font-style: normal;
    font-weight: 400;
    &:not(:placeholder-shown) ~ label, &:focus ~ label {
      transform: scale(0.85) translateY(4px) translateX(0);
      font-size: 11px;
      padding: 0 14px;
      height: auto;
    }
    &:disabled {
      background: $gray-100;
    }
  }
  .form-label {
    padding: 0 14px;
    display: flex;
    align-items: center;
    line-height: normal !important;
    color: #6D7581 !important;
    font-family: $font-family-sans-pro;
    margin: 0 !important;
    &::after {
      inset: unset !important;
      background-color: transparent !important;
    }
  }
}

.blue-btn {
  background: $primary;
  height: 50px;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.14px;
  padding: 0px 30px;
}